import React from "react";

// Components
import { graphql } from "gatsby";
import Image from "gatsby-image";
import SEO from "../shared/SEO";

// Style
import styled from "styled-components";
import { neuzeitGroLight, fontSize } from "../../styles/fonts";

const Container = styled.div`
  position: relative;
`;

const Text = styled.div`
  ${neuzeitGroLight}
  font-size: ${fontSize.xl};
  color: white;
  font-weight: 600;
  position: absolute;
  bottom: 0;
  right: 20px;
`;

const NoResults = ({ data }) => {
  const imageInfo = data.fileName.childImageSharp.fluid;

  return (
    <>
      <SEO content={{ title: "404", excerpt: "Resource not found" }} />
      <Container>
        <Image title="404" fluid={imageInfo} />
        <Text>404</Text>
      </Container>
    </>
  );
};

export default NoResults;

export const query = graphql`
  query {
    fileName: file(relativePath: { eq: "window.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
